import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import HeroSlider from '../Hero-Slider/HeroSlider'

import sectionImage00 from './../../images/section-bg01.png'
import sectionImage01 from './../../images/section-image01.jpeg'
import sectionImage02 from './../../images/section-bg01.png'

const Home = () => {
  return (
    <>
      <HeroSlider />
      <section className="content-section" data-background={sectionImage00} data-stellar-background-ratio="1.2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="side-content left">
                <h2>Welcome</h2>
                <h6>Indian Vegetable experts and distributors</h6>
                <p>Our products are the native products with no compromise on nativity and nutritional contributions for healthy life style. We source our seeds from the best in the market with no room for comprising. Our nutritional experts compromise the core of our business. We use SABS approved fertilizers and latest farming concepts to bring the best for our clients and customers.</p>
                <Link to="/" className="custom-button">
                  MORE INFORMATION
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="side-slider">
                <div className="slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <StaticImage quality={100} src="../../images/side-slide01.jpg" alt="slide-one" />
                    </div>
                    <div className="swiper-slide">
                      <StaticImage quality={100} src="../../images/side-slide01.jpg" alt="slide-two" />
                    </div>
                    <div className="swiper-slide">
                      <StaticImage quality={100} src="../../images/side-slide01.jpg" alt="slide-three" />
                    </div>
                  </div>
                  <div className="swiper-pagination"></div>
                </div>
                <div className="note-box">Real results real farm</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-top-spacing">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="image-content-box">
                <h5>Square Roots</h5>
                <figure>
                  <StaticImage quality={100} src="./../../images/image-box01.jpg" alt="Image" />
                </figure>
                <p>Square Roots is an urban farming accelerator that was started with 10 of Freight Farms</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="image-content-box">
                <h5>Farm One</h5>
                <figure>
                  <StaticImage quality={80} src="./../../images/image-box02.jpg" alt="Image" />
                </figure>
                <p>Our high-tech vertical farms grow fresh microgreens, herbs & flowers Order online for local delivery.</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="image-content-box">
                <h5>Sky Vegetables</h5>
                <figure>
                  <StaticImage quality={80} src="./../../images/image-box03.jpg" alt="Image" />
                </figure>
                <p>Our high-tech vertical farms grow fresh microgreens, herbs & flowers Order online for local delivery.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section" data-background="#fbf7f4">
        <div className="container">
          <div className="section-title">
            <figure>
              <StaticImage quality={80} src="../../images/section-title-shape.png" alt="Image" />
            </figure>
            <h6>Presidential campaigns</h6>
            <h2>Commitment to the Environment</h2>
          </div>
        </div>
        <div className="carousel-image-box">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <figure>
                <StaticImage quality={80} src="./../../images/carousel-image01.jpg" alt="Image" />
              </figure>
              <div className="content">
                <small>Dynamic on using green</small>
                <h5>Dynamic Guidance Dunescapes</h5>
                <p>Dynamic Dunescapes is and ambitious journey we are exploring and rejuvenations in South Africa.</p>
              </div>
            </div>
            <div className="swiper-slide">
              <figure>
                <StaticImage quality={80} src="./../../images/carousel-image02.jpg" alt="Image" />
              </figure>
              <div className="content">
                <small>Guidance on using green</small>
                <h5>Tips for Ripening your Fruit</h5>
                <p>Rezilon herbicide uses the active ingredient indaziflam to target broadleaf and annual grass. </p>
              </div>
            </div>
            <div className="swiper-slide">
              <figure>
                <StaticImage quality={80} src="./../../images/carousel-image03.jpg" alt="Image" />
              </figure>
              <div className="content">
                <small>Guidance on using green</small>
                <h5>Health Benefits of a Raw Food</h5>
                <p>Wheat futures were lower overnight while corn and soybean futures were little changed.</p>
              </div>
            </div>
            <div className="swiper-slide">
              <figure>
                <StaticImage quality={80} src="./../../images/carousel-image04.jpg" alt="Image" />
              </figure>
              <div className="content">
                <small>Guidance on using green</small>
                <h5>Superfoods you should be eating</h5>
                <p>Farm managers, land brokers, appraisers, and other professionals note a modest decline.</p>
              </div>
            </div>
            <div className="swiper-slide">
              <figure>
                <StaticImage quality={80} src="./../../images/carousel-image05.jpg" alt="Image" />
              </figure>
              <div className="content">
                <small>Guidance on using green</small>
                <h5>Fields of Corns Dunescapes</h5>
                <p>Wheat futures were higher in overnight trading corn futures and soybean futures were little changed.</p>
              </div>
            </div>
            <div className="swiper-slide">
              <figure>
                <StaticImage quality={80} src="./../../images/carousel-image06.jpg" alt="Image" />
              </figure>
              <div className="content">
                <small>Guidance on using green</small>
                <h5>Discipline Is the Name of the Game</h5>
                <p>There will be opportunities to market your grain, and we want to encourage you to think about.</p>
              </div>
            </div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </section>

      

      <section className="content-section no-bottom-spacing" data-background={sectionImage01}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-9">
              <div className="services-list-box">
                <h5>WHAT WE DO</h5>
                <h2>Our Services</h2>
                <ul>
                  <li>
                    <figure>
                      <StaticImage quality={100} src="./../../images/icon08.png" alt="Image" />
                    </figure>
                    <div className="content">
                      <h6>Organic Fertiliser</h6>
                      <p>vegetables from our farms is delivered to market daily, ensuring you only eat the best of what &#39; s in the season</p>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <StaticImage quality={100} src="./../../images/icon09.png" alt="Image" />
                    </figure>
                    <div className="content">
                      <h6>Cumulative Farming</h6>
                      <p>We plead our neighbours to come to the game and join us in this journey to provide a variety of products to our clients by joining our community and becoming a cumulative farmers.</p>
                    </div>
                  </li>
                  <li>
                    <figure>
                      <StaticImage quality={100} src="./../../images/icon10.png" alt="Image" />
                    </figure>
                    <div className="content">
                      <h6>Water Management</h6>
                      <p>We had incorporated the principle and disciplines set out by Water Gandhi and our farms deliver the best by ensuring that water is used to its best.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section" data-background={sectionImage02} data-stellar-background-ratio="1.2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-title">
                <figure>
                  <StaticImage quality={100} src="../../images/section-title-shape.png" alt="Image" />
                </figure>
                <h6>Discover nature</h6>
                <h2>We have expertise in these areas</h2>
              </div>
            </div>
            <div className="col-lg-8">
              <figure className="side-image">
                <StaticImage quality={100} src="../../images/side-image01.jpg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-4">
              <div className="side-content right">
                <h5>Sustainability and Climate Change</h5>
                <p>Our agricultural background means we’re equally at home meeting face-to-face with farmers as we are engaging.</p>
                <a href="index.html#" className="custom-link">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-12 spacing-100"></div>
            <div className="col-lg-4">
              <div className="side-content left">
                <h5>Advice and farm implement</h5>
                <p>Largest independent provider of agricultural and environmental consultancy, rural development services and policy advice.</p>
                <a href="index.html#" className="custom-link">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-8">
              <figure className="side-image">
                <StaticImage quality={100} src="../../images/side-image02.jpg" alt="Image" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section no-top-spacing">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-title">
                <h2>Why we&apos;re different</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <figure>
                  <StaticImage quality={100} src="../../images/icon01.png" alt="Image" />
                </figure>
                <div className="content">
                  <p>We are straight forward to deal with experts in our field.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <figure>
                  <StaticImage quality={100} src="../../images/icon02.png" alt="Image" />
                </figure>
                <div className="content">
                  <p>We take farming and its responsibilities Seriously than once can imagine</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <figure>
                  <StaticImage quality={100} src="../../images/icon03.png" alt="Image" />
                </figure>
                <div className="content">
                  <p>If we can’t help you, we will tell you quickly and honestly. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <figure>
                  <StaticImage quality={100} src="../../images/icon04.png" alt="Image" />
                </figure>
                <div className="content">
                  <p>You can talk to one of our experts for a free advice at no cost. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <figure>
                  <StaticImage quality={100} src="../../images/icon05.png" alt="Image" />
                </figure>
                <div className="content">
                  <p>We offer guidance and knowledge to put our farmers in the right path. </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box">
                <figure>
                  <StaticImage quality={100} src="../../images/icon06.png" alt="Image" />
                </figure>
                <div className="content">
                  <p>Our Experience is growing like plant by sharing and caring. This applies to both farms and outside farmers</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h6>Consultative partners</h6>
                <h2>
                  Partners who share their knowledge and <br />
                  experience in agriculture
                </h2>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <figure className="logo-item">
                <StaticImage quality={100} src="../../images/client-logo/cli-logo-one.jpeg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <figure className="logo-item">
                <StaticImage quality={100} src="../../images/client-logo/cli-logo-two.jpeg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <figure className="logo-item">
                <StaticImage quality={100} src="../../images/client-logo/cli-logo-three.jpeg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <figure className="logo-item">
                <StaticImage quality={100} src="../../images/client-logo/cli-logo-four.jpeg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <figure className="logo-item">
                <StaticImage quality={100} src="../../images/client-logo/cli-logo-five.jpeg" alt="Image" />
              </figure>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
              <figure className="logo-item">
                <StaticImage quality={100} src="../../images/client-logo/cli-logo-six.jpeg" alt="Image" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
