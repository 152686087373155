import React from 'react';
import { Link } from 'gatsby';
import slide01 from './../../images/slide01.jpeg';
import slide02 from './../../images/slide02.jpg';
import slide03 from './../../images/slide03.jpg';

const HeroSlider = () => {
  return (
    <header className="header">
      <div className="main-slider">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="slide-image swiper-lazy" data-background={slide01}></div>
            <div className="slide-inner">
              <h1>What’s Things in Season?</h1>
              <p>Your Guide to Eating Seasonally</p>
              <Link to="/products">VIEW OUR PRODUCTS</Link>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-image swiper-lazy" data-background={slide02}></div>
            <div className="slide-inner">
              <h1>What’s Things in Season?</h1>
              <p>Your Guide to Eating Seasonally</p>
              <Link to="/products">VIEW OUR PRODUCTS</Link>
            </div>
          </div>
          <div className="swiper-slide">
            <div className="slide-image swiper-lazy" data-background={slide03}></div>
            <div className="slide-inner">
              <h1>What’s Things in Season?</h1>
              <p>Your Guide to Eating Seasonally</p>
              <Link to="/products">VIEW OUR PRODUCTS</Link>
            </div>
          </div>
        </div>
        <div className="button-prev">
          <i className="lni lni-chevron-left"></i>
        </div>
        <div className="button-next">
          <i className="lni lni-chevron-right"></i>
        </div>
        <div className="swiper-pagination"></div>
        <div className="parallax-element" data-stellar-ratio="2"></div>
      </div>
    </header>
  );
};

export default HeroSlider;
