import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Home from '../components/Home/Home'

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Welcome" />
      <Home />
    </Layout>
  )
}

export default IndexPage
